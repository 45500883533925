import {all, takeEvery} from 'redux-saga/effects';
import types from './types';
import {NavigationPayload} from './actions';
import {Action} from 'redux-actions';

function goBack({payload}: Action<NavigationPayload>) {
  payload.history.goBack();
}

function navigateToMain({payload}: Action<NavigationPayload>) {
  payload.history.push('/main');
}

export default function* () {
  yield all([
    takeEvery(types.GO_BACK, goBack),
    takeEvery(types.NAVIGATE_TO_MAIN, navigateToMain),
  ]);
}
