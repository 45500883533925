import React from 'react';
import styles from './MapSection.module.scss';
// import {compose, withProps} from 'recompose';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   // Marker,
//   Polyline,
// } from 'react-google-maps';
import {useTranslation} from 'react-i18next';
// import {MapImg} from './assets';

// const MyMapComponent = compose(
//   withProps({
//     googleMapURL:
//       'https://maps.googleapis.com/maps/api/js?key=AIzaSyASty2wJrsji6Mxt8LXLFp5yUlGtSzLfKk&v=3.exp&libraries=geometry,drawing,places',
//     loadingElement: <div className={styles.mapSection__map} />,
//     containerElement: <div className={styles.mapSection__mapContainer} />,
//     mapElement: <div className={styles.mapSection__map} />,
//   }),
//   withScriptjs,
//   withGoogleMap,
// )(() => (
//   <GoogleMap defaultZoom={5} defaultCenter={{lat: 49.042778, lng: 31.821944}}>
//     <Polyline
//       path={[
//         {lat: 48.419167, lng: 22.137222},
//         {lat: 44.387222, lng: 33.738056},
//         {lat: 49.260556, lng: 40.228056},
//         {lat: 52.378611, lng: 33.191944},
//       ]}
//     />
//   </GoogleMap>
// ));

const MapSection: React.FC = () => {
  const {t} = useTranslation('mapSection');
  return (
    <div className={styles.mapSection} id="contactsSection">
      <div className={styles.mapSection__contactsContainer}>
        <div className={styles.mapSection__contactsBlock}>
          <h3>{t('fieldName1')}</h3>
          <div className={styles.mapSection__contactsValues}>
            <p>{t('fieldValue1')}</p>
            <p>{t('fieldValue2')}</p>
          </div>
        </div>
        <div className={styles.mapSection__contactsBlock}>
          <h3>{t('fieldName2')}</h3>
          <div className={styles.mapSection__contactsValues}>
            <a href={`mailto:${t('fieldValue3')}`}>{t('fieldValue3')}</a>
          </div>
          <div className={styles.mapSection__contactsValues}>
            <a href={`mailto:${t('fieldValue4')}`}>{t('fieldValue4')}</a>
          </div>
        </div>
        <div className={styles.mapSection__contactsBlock}>
          <h3>{t('fieldName3')}</h3>
          <div className={styles.mapSection__contactsValues}>
            <a href={`tel:${t('fieldValue5')}`}>{t('fieldValue5')}</a>
            <a href={`tel:${t('fieldValue6')}`}>{t('fieldValue6')}</a>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6145402.321002501!2d30.768012159222373!3d49.35047593511934!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a09f63ab0f8b%3A0x2d4c18681aa4be0a!2z0KXQsNGA0YzQutC-0LIsINCl0LDRgNGM0LrQvtCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0Yw!5e0!3m2!1sru!2sua!4v1589120029987!5m2!1sru!2sua"
        frameBorder="0"
        style={{border: 0}}
        allowFullScreen={false}
        aria-hidden="false"
        title="companyAddressOnMap"
      />
    </div>
  );
};

export default MapSection;
