import React from 'react';
import styles from './ServicesSection.module.scss';
import {SectionNumber} from './assets';
import {useTranslation} from 'react-i18next';

const ServicesSection: React.FC = () => {
  const {t} = useTranslation('servicesSection');
  return (
    <div className={styles.servicesSection}>
      <p className={styles.servicesSection__shortName}>
        <span>03&emsp;</span>
        {t('shortName')}
      </p>
      <img
        className={styles.servicesSection__sectionNumber}
        src={SectionNumber}
        alt={t('sectionNumber')}
      />
      <div className={styles.servicesSection__descriptionContainer}>
        <h2>{t('title')}</h2>
        <p>{t('paragraph1')}</p>
      </div>
      <div className={styles.servicesSection__previewContainer}>
        <div className={styles.servicesSection__firstPreview}>
          <div className={styles.servicesSection__hover} />
          <p>{t('preview1')}</p>
        </div>
        <div className={styles.servicesSection__secondPreview}>
          <div className={styles.servicesSection__hover} />
          <p>{t('preview2')}</p>
        </div>
        <div className={styles.servicesSection__thirdPreview}>
          <div className={styles.servicesSection__hover} />
          <p>{t('preview3')}</p>
        </div>
        <div className={styles.servicesSection__fourthPreview}>
          <div className={styles.servicesSection__hover} />
          <p>{t('preview4')}</p>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
