import React from 'react';
import styles from './ProjectsSection.module.scss';
import {
  SectionNumber,
  // ArrowRightImg,
  Project1Img,
  Project2Img,
  Project3Img,
} from './assets';
import Project from 'entities/Project';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';

const images = [
  {
    id: '36fe42b6-6f97-4dd2-be37-38359a013a5f',
    image: Project1Img,
  },
  {
    id: '4987af1a-ea1d-42e1-97c2-213b8de3afaa',
    image: Project2Img,
  },
  {
    id: '6f15d451-a4a7-447b-ad09-4eee8fee9ef1',
    image: Project3Img,
  },
];

const projects: Project[] = [
  {
    id: '780503bd-a097-4470-82c6-14fc1638f3f2',
    rating: 4.2,
    imageId: '4987af1a-ea1d-42e1-97c2-213b8de3afaa',
    title: 'US Ready',
    description: '',
    technologies: [],
  },
  {
    id: 'da939f6a-b8ca-4f6c-bbb5-7d53bf3fe935',
    rating: 4.7,
    imageId: '6f15d451-a4a7-447b-ad09-4eee8fee9ef1',
    title: 'SaQure',
    description: '',
    technologies: [],
  },
  {
    id: '22938137-585c-48b4-b84e-f82faa626770',
    rating: 4.4,
    imageId: '36fe42b6-6f97-4dd2-be37-38359a013a5f',
    title: 'The be mindset',
    description: '',
    technologies: [],
  },
];

const ProjectsSection: React.FC = () => {
  const {t} = useTranslation('projectsSection');
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
  };
  return (
    <div className={styles.projectsSection} id="projectsSection">
      <div className={styles.projectsSection__titleContainer}>
        <p className={styles.projectsSection__shortName}>
          <span>04&emsp;</span>
          {t('shortName')}
        </p>
        <img
          className={styles.projectsSection__sectionNumber}
          src={SectionNumber}
          alt={t('sectionNumber')}
        />
        <div className={styles.projectsSection__descriptionContainer}>
          <h2>{t('title')}</h2>
        </div>
      </div>
      <div className={styles.projectsSection__cardsContainer}>
        <div className={styles.projectsSection__sliderContainer}>
          <Slider className={styles.businessModelsSection__slider} {...settings}>
            {projects.map((item) => {
              const img = images.find((image) => image.id === item.imageId);
              return (
                <div className={styles.projectsSection__projectSlide}>
                  {img && (
                    <img
                      src={img.image}
                      alt={`${item.title} ${t('projectPreviewAlt')}`}
                    />
                  )}
                </div>
              );
            })}
          </Slider>
        </div>
        <div className={styles.projectsSection__cards}>
          {projects
            .sort((a, b) => (a.rating > b.rating ? -1 : 1))
            .slice(0, 3)
            .map((item) => {
              const img = images.find((image) => image.id === item.imageId);
              return (
                <div className={styles.projectsSection__projectSlide}>
                  {img && (
                    <img
                      src={img.image}
                      alt={`${item.title} ${t('projectPreviewAlt')}`}
                    />
                  )}
                </div>
              );
            })}
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {/* <a href="/projects"> */}
        {/*  {t('linkToProjects')} */}
        {/*  <img src={ArrowRightImg} alt={t('linkToProjectsImgAlt')} /> */}
        {/* </a> */}
      </div>
    </div>
  );
};

export default ProjectsSection;
