import {MailSenderConfig} from './MailSenderConfig';
import {IMailSender} from './IMailSender';
import EmailJs from 'emailjs-com';

export class MailSender implements IMailSender {
  constructor(private config: MailSenderConfig) {
    EmailJs.init(this.config.userId);
  }

  async sendMessage(
    fromName: string,
    fromContact: string,
    toName: string,
    messageText: string,
    templateId: string,
  ): Promise<void> {
    const params = {
      message_text: messageText,
      from_name: fromName,
      from_contact: fromContact,
      to_name: toName,
    };
    await EmailJs.send(this.config.serviceId, templateId, params);
  }
}
