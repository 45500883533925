import React from 'react';
import styles from './Logo.module.scss';
import {LogoImg} from './assets';
import {Link} from 'react-scroll';

const Logo: React.FC = () => {
  return (
    <Link
      activeClass="active"
      to="banner"
      spy
      smooth
      offset={-70}
      duration={500}
      className={styles.logo}
    >
      <img src={LogoImg} className={styles.logo_img_container} alt="SPRY ROCKS" />
      <strong className={styles.title_text}>Spry Rocks</strong>
    </Link>
  );
};

export default Logo;
