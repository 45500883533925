import React, {useEffect, useState} from 'react';
import Logo from '../Logo/Logo';
import styles from './Header.module.scss';
import {useTranslation} from 'react-i18next';
// React Component
// @ts-ignore
import Burger from '@animated-burgers/burger-squeeze';
import '@animated-burgers/burger-squeeze/dist/styles.css';
// @ts-ignore
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
import {Link} from 'react-scroll';

const Header: React.FC = () => {
  const {t} = useTranslation('header');
  const [showMenu, setShowMenu] = useState(false);
  smoothscrollAnchorPolyfill.polyfill();

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  function useCurrentWidth() {
    // save current window width in the state object
    const [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
      // timeoutId for debounce mechanism
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout();
        // change width from the state object after 150 milliseconds
        setTimeout(() => setWidth(getWidth()), 150);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      };
    }, []);

    return width;
  }

  const Width = useCurrentWidth();

  return (
    <div className={styles.header}>
      <div className={styles.header__container}>
        <Logo />
        <ul>
          <li>
            <Link
              activeClass="active"
              to="aboutSection"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              {t('aboutSectionLink')}
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="technologiesSection"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              {t('technologiesSectionLink')}
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="bussinessModelsSection"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              {t('businessModelsSectionLink')}
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="projectsSection"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              {t('projectsSectionLink')}
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="reviewSection"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              {t('reviewSectionLink')}
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="contactsSection"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              {t('contactsSectionLink')}
            </Link>
          </li>
        </ul>
        <Burger
          isOpen={showMenu}
          Component="button"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
          className={styles.burgerMenu}
        />
      </div>
      <div className={showMenu && Width < 1400 ? styles.show : styles.hide}>
        <ul className={styles.openedMenu}>
          <li className={styles.openedMenu__item}>
            <Link
              activeClass="active"
              to="aboutSection"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => setShowMenu(false)}
            >
              {t('aboutSectionLink')}
            </Link>
          </li>
          <li className={styles.openedMenu__item}>
            <Link
              activeClass="active"
              to="technologiesSection"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => setShowMenu(false)}
            >
              {t('technologiesSectionLink')}
            </Link>
          </li>
          <li className={styles.openedMenu__item}>
            <Link
              activeClass="active"
              to="bussinessModelsSection"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => setShowMenu(false)}
            >
              {t('businessModelsSectionLink')}
            </Link>
          </li>
          <li className={styles.openedMenu__item}>
            <Link
              activeClass="active"
              to="projectsSection"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => setShowMenu(false)}
            >
              {t('projectsSectionLink')}
            </Link>
          </li>
          <li className={styles.openedMenu__item}>
            <Link
              activeClass="active"
              to="reviewSection"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => setShowMenu(false)}
            >
              {t('reviewSectionLink')}
            </Link>
          </li>
          <li className={styles.openedMenu__item}>
            <Link
              activeClass="active"
              to="contactsSection"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => setShowMenu(false)}
            >
              {t('contactsSectionLink')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
