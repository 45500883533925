import {IMailSender} from './mailSender/IMailSender';
import {MailSender} from './mailSender/MailSender';
import firebase from '@firebase/app';
import '@firebase/analytics';

const mailSender: IMailSender = new MailSender({
  userId: 'user_jEw9EXnJMXp2GMAV0nIal',
  serviceId: 'default_service',
});

const firebaseConfig = {
  apiKey: 'AIzaSyAMz89ILYSBdLQzxx39GNMTp6lDVDB8slM',
  authDomain: 'spryrockswebsite.firebaseapp.com',
  databaseURL: 'https://spryrockswebsite.firebaseio.com',
  projectId: 'spryrockswebsite',
  storageBucket: 'spryrockswebsite.appspot.com',
  messagingSenderId: '58105014127',
  appId: '1:58105014127:web:7d74a379feac9bd920ea9a',
  measurementId: 'G-921QE4SXNY',
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export {
  //
  mailSender as MailSender,
};
