import translations from 'resources/Translations.json';
import Localization from './localization/Localization';
import {configureStore} from 'state/StateInitializer';
import {rootSaga} from 'state/ducks';

// region Helpers
// endregion

const initAsync = async () => {
  await Localization.initAsync(translations);

  await configureStore(rootSaga);
};

export {
  //
  initAsync,
};
