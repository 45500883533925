import React from 'react';
import styles from './Footer.module.scss';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-scroll';

const Footer: React.FC = () => {
  const {t} = useTranslation('footer');
  return (
    <div className={styles.footer}>
      <div className={styles.footer__company}>
        <p className={styles.footer__companyName}>Spry Rocks</p>
        <p className={styles.footer__companyScope}>{t('companyScope')}</p>

        <div className={styles.footer__navSM}>
          <div>
            <ul>
              <li>
                <Link
                  activeClass="active"
                  to="aboutSection"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  {t('aboutSectionLink')}
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="technologiesSection"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  {t('technologiesSectionLink')}
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="bussinessModelsSection"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  {t('businessModelsSectionLink')}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <Link
                  activeClass="active"
                  to="projectsSection"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  {t('projectsSectionLink')}
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="reviewSection"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  {t('reviewSectionLink')}
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="contactsSection"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  {t('contactsSectionLink')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.footer__nav}>
        <div>
          <ul>
            <li>
              <Link
                activeClass="active"
                to="aboutSection"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                {t('aboutSectionLink')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="technologiesSection"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                {t('technologiesSectionLink')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="bussinessModelsSection"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                {t('businessModelsSectionLink')}
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <Link
                activeClass="active"
                to="projectsSection"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                {t('projectsSectionLink')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="reviewSection"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                {t('reviewSectionLink')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="contactsSection"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                {t('contactsSectionLink')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.footer__contactsInfo}>
        <div>
          <p>{t('contactsInfo1')}</p>
          <p>{t('contactsInfo2')}</p>
        </div>
        <div>
          <a href={`mailto:${t('contactsInfo3')}`}>{t('contactsInfo3')}</a>
          <a href={`mailto:${t('contactsInfo4')}`}>{t('contactsInfo4')}</a>
        </div>
        <a href={`tel:${t('contactsInfo5')}`}>{t('contactsInfo5')}</a>
      </div>
    </div>
  );
};

export default Footer;
