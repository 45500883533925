import React from 'react';
import styles from './BusinessModelsSection.module.scss';
import {
  SectionNumber,
  // ArrowRight1Img,
  // ArrowRight2Img,
  // ArrowRight3Img,
  // ArrowRightDefaultImg,
  CardImage1Img,
  CardImage2Img,
  CardImage3Img,
} from './assets';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import {Link} from 'react-scroll';

const BusinessModelsSection: React.FC = () => {
  const {t} = useTranslation('businessModelsSection');
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
  };
  return (
    <div className={styles.businessModelsSection} id="bussinessModelsSection">
      <div className={styles.businessModelsSection__titleContainer}>
        <p className={styles.businessModelsSection__shortName}>
          <span>04&emsp;</span>
          {t('shortNamePart1')}&emsp;
          <span>{t('shortNamePart2')}</span>
        </p>
        <img
          className={styles.businessModelsSection__sectionNumber}
          src={SectionNumber}
          alt={t('sectionNumber')}
        />
        <div className={styles.businessModelsSection__descriptionContainer}>
          <h2>{t('title')}</h2>
        </div>
      </div>
      <div className={styles.businessModelsSection__sliderContainer}>
        <Slider className={styles.businessModelsSection__slider} {...settings}>
          <div
            className={`${styles.businessModelsSection__card1}, ${styles.businessModelsSection__sliderCard}`}
          >
            <h3>{t('cardTitle1')}</h3>
            <p>{t('cardDescription1')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a href="#"> */}
            {/*  {t('linkToDetails')} */}
            {/*  <img src={ArrowRightDefaultImg} alt={t('linkToDetailsImgAlt1')} /> */}
            {/* </a> */}
          </div>
          <div
            className={`${styles.businessModelsSection__card2}, ${styles.businessModelsSection__sliderCard}`}
          >
            <h3>{t('cardTitle2')}</h3>
            <p>{t('cardDescription2')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a href="#"> */}
            {/*  {t('linkToDetails')} */}
            {/*  <img src={ArrowRightDefaultImg} alt={t('linkToDetailsImgAlt2')} /> */}
            {/* </a> */}
          </div>
          <div
            className={`${styles.businessModelsSection__card3}, ${styles.businessModelsSection__sliderCard}`}
          >
            <h3>{t('cardTitle3')}</h3>
            <p>{t('cardDescription3')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a href="#"> */}
            {/*  {t('linkToDetails')} */}
            {/*  <img src={ArrowRightDefaultImg} alt={t('linkToDetailsImgAlt3')} /> */}
            {/* </a> */}
          </div>
        </Slider>
      </div>
      <div className={styles.businessModelsSection__cardsContainer}>
        <div className={styles.businessModelsSection__cards}>
          <div className={styles.businessModelsSection__card1}>
            <img src={CardImage1Img} alt={t('cardImgAlt1')} />
            <h3>{t('cardTitle1')}</h3>
            <p>{t('cardDescription1')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a href="#"> */}
            {/*  {t('linkToDetails')} */}
            {/*  <img src={ArrowRight1Img} alt={t('linkToDetailsImgAlt1')} /> */}
            {/* </a> */}
          </div>
          <div className={styles.businessModelsSection__card2}>
            <img src={CardImage2Img} alt={t('cardImgAlt2')} />
            <h3>{t('cardTitle2')}</h3>
            <p>{t('cardDescription2')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a href="#"> */}
            {/*  {t('linkToDetails')} */}
            {/*  <img src={ArrowRight2Img} alt={t('linkToDetailsImgAlt2')} /> */}
            {/* </a> */}
          </div>
          <div className={styles.businessModelsSection__card3}>
            <img src={CardImage3Img} alt={t('cardImgAlt3')} />
            <h3>{t('cardTitle3')}</h3>
            <p>{t('cardDescription3')}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a href="#"> */}
            {/*  {t('linkToDetails')} */}
            {/*  <img src={ArrowRight3Img} alt={t('linkToDetailsImgAlt3')} /> */}
            {/* </a> */}
          </div>
        </div>
        <Link
          activeClass="active"
          to="formSection"
          spy
          smooth
          offset={-70}
          duration={500}
          className={styles.businessModelsSection__estimateButton}
        >
          {t('estimateButton')}
        </Link>
      </div>
    </div>
  );
};

export default BusinessModelsSection;
