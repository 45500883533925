import {saga as routerSaga} from './router';
import {saga as alertSaga} from './alert';
import {all} from 'redux-saga/effects';
import State from 'state/entities/State';
import {combineReducers} from 'redux';

export const rootReducer = combineReducers<State>({});

export function* rootSaga() {
  yield all([routerSaga(), alertSaga()]);
}
