import React from 'react';
import styles from './Sections.module.scss';
import {
  AboutSection,
  TechnologiesSection,
  ServicesSection,
  BusinessModelsSection,
  ProjectsSection,
  FeedbackSection,
} from 'components';

const Sections: React.FC = () => {
  return (
    <div className={styles.sections}>
      <AboutSection />
      <TechnologiesSection />
      <ServicesSection />
      <BusinessModelsSection />
      <ProjectsSection />
      <FeedbackSection />
      {/* <PresentationDownloadSection /> */}
    </div>
  );
};

export default Sections;
