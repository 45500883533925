import React from 'react';
import styles from './FeedbackSection.module.scss';
import {SectionNumber, StarImg, UpWorkImg} from './assets';
import Feedback from 'entities/Feedback';
import {useTranslation} from 'react-i18next';

const feedback: Feedback[] = [
  {
    id: '0e2b2a9f-da94-46ac-8b90-361a8476c22d',
    rating: 4,
    feedback:
      'Spry Rocks team have been a real pleasure to work with! They were involved and proactive, everything was delivered in time during the whole length of the project.',
    author: 'Tamara Starink',
    position: 'Enterpreneur',
  },
  {
    id: '43981d0f-d6a2-4f26-a018-53dfd25fd217',
    rating: 5,
    feedback:
      'Great group to work with. Solid communication and worked with me on price and scope.',
    author: 'Conor Lamb',
    position: 'Startup owner',
  },
  {
    id: '30e080c6-61df-4516-bf7b-4f3f32d2f3b9',
    rating: 5,
    feedback:
      'They absolutely phenomenal. I highly recommend them. Have worked closely to realize some rather complicated design and specifications and met every milestone. Everyone should hire them now, very talanted and professionally run mobile app development agency.',
    author: 'Scon Olson',
    position: 'Businessman',
  },
  {
    id: '1dbf6af6-1dad-4114-80e8-62111a030e05',
    rating: 5,
    feedback:
      'This freelancer was ideal in every way. Prepared perfectly, communicated exceptionally well (even when we had unforeseen obstacles), helped in every imaginable way to help move the process forward (was always on track), and completed outstanding work. I look forward to utilizing this freelancer again in the future. I recommend very highly, you will be very happy.',
    author: 'Chris Habib',
    position: 'Enterpreneur',
  },
];
const FeedbackSection: React.FC = () => {
  const {t} = useTranslation('feedbackSection');
  return (
    <div className={styles.feedbackSection} id="reviewSection">
      <div className={styles.feedbackSection__titleContainer}>
        <p className={styles.feedbackSection__shortName}>
          <span>06&emsp;</span>
          {t('shortName')}
        </p>
        <img
          className={styles.feedbackSection__sectionNumber}
          src={SectionNumber}
          alt={t('sectionNumber')}
        />
        <div className={styles.feedbackSection__descriptionContainer}>
          <h2>{t('title')}</h2>
        </div>
      </div>
      <div className={styles.feedbackSection__feedbackContainer}>
        <div className={styles.feedbackSection__container}>
          <h3>{t('subTitle')}</h3>
          <div className={styles.feedbackSection__feedback}>
            {feedback.slice(0, 4).map((item) => {
              return (
                <div>
                  <div className={styles.feedbackSection__feedbackStars}>
                    {[...Array(5)].map(() => (
                      <img src={StarImg} alt={t('starImgAlt')} />
                    ))}
                  </div>
                  <p className={styles.feedbackSection__feedbackText}>
                    {`“${item.feedback}”`}
                  </p>
                  <p className={styles.feedbackSection__feedbackAuthor}>
                    {`${item.author}, ${item.position}`}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <a
          href="https://www.upwork.com/o/companies/~014b5336808873666a/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={UpWorkImg} alt="UpWork" />
        </a>
      </div>
    </div>
  );
};

export default FeedbackSection;
