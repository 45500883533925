import React from 'react';
import styles from './Banner.module.scss';
import {ScrollDownImg} from './assets';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-scroll';

const Banner: React.FC = () => {
  const {t} = useTranslation('banner');
  return (
    <div className={styles.banner} id="banner">
      <h1 className={styles.banner__title}>{t('title')}</h1>
      <p className={styles.banner__description}>{t('description')}</p>
      <Link
        activeClass="active"
        to="formSection"
        spy
        smooth
        offset={-70}
        duration={500}
        className={styles.banner__estimateButton}
      >
        {t('estimateButton')}
      </Link>
      <Link activeClass="active" to="aboutSection" spy smooth offset={-70} duration={500}>
        <img
          className={styles.banner__scrollButton}
          src={ScrollDownImg}
          alt={t('scrollButton')}
        />
      </Link>
    </div>
  );
};

export default Banner;
