import React from 'react';
import styles from './Main.module.scss';
import {Header, Banner, Sections, FormSection, MapSection, Footer} from 'components';

const Main: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <Banner />
      <Sections />
      <FormSection />
      <MapSection />
      <Footer />
    </div>
  );
};

export default Main;
