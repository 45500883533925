import React, {useState} from 'react';
import styles from './FormSection.module.scss';
import {SectionNumber} from './assets';
import {useTranslation} from 'react-i18next';
import {MailSender} from '../../services';

const FormSection: React.FC = () => {
  const {t} = useTranslation('formSection');

  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [description, setDescription] = useState('');
  const [isBusy, setBusy] = useState(false);

  const sendFeedback = async (name: string, contact: string, description: string) => {
    if (isBusy) return;

    try {
      setBusy(true);

      await MailSender.sendMessage(
        name,
        contact,
        'Spry Rocks team',
        description,
        'EstimateProjectTemplate',
      );

      setName('');
      setContact('');
      setDescription('');

      // eslint-disable-next-line
      alert(
        'Message sent, thank you touching us, our manager will contact you as soon as possible!',
      );
    } catch (e) {
      // eslint-disable-next-line
      alert('Oops, something went wrong, please try again.');
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className={styles.formSection} id="formSection">
      <img
        className={styles.formSection__sectionNumber}
        src={SectionNumber}
        alt={t('sectionNumber')}
      />
      <div className={styles.formSection__content}>
        <h3>{t('title')}</h3>
        <p>{t('description')}</p>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            sendFeedback(name, contact, description).then();
          }}
        >
          <label htmlFor="name">
            {t('fieldName1')}
            <input
              id="name"
              name="name"
              type="text"
              placeholder={t('fieldPlaceholder1')}
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </label>
          <label htmlFor="contact">
            {t('fieldName2')}
            <input
              id="contact"
              name="contact"
              type="text"
              placeholder={t('fieldPlaceholder2')}
              required
              value={contact}
              onChange={(event) => setContact(event.target.value)}
            />
          </label>
          <label htmlFor="description">
            {t('fieldName3')}
            <textarea
              id="description"
              name="description"
              cols={30}
              rows={10}
              placeholder={t('fieldPlaceholder3')}
              required
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </label>
          <div>
            <button type="submit" disabled={isBusy}>
              {t('button')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormSection;
