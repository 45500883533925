import React from 'react';
import styles from './AboutSection.module.scss';
import {
  AboutSectionPreview1Img,
  AboutSectionPreview2Img,
  AboutSectionPreview3Img,
  AboutSectionPreview4Img,
  SectionNumber,
} from './assets';
import {useTranslation} from 'react-i18next';

const AboutSection: React.FC = () => {
  const {t} = useTranslation('aboutSection');
  return (
    <div className={styles.aboutSection} id="aboutSection">
      <p className={styles.aboutSection__shortName}>
        <span>01&emsp;</span>
        {t('shortName')}
      </p>
      <img
        className={styles.aboutSection__sectionNumber}
        src={SectionNumber}
        alt={t('sectionNumber')}
      />
      <div className={styles.aboutSection__descriptionContainer}>
        <h2>{t('title')}</h2>
        <div className={styles.singlePreview}>
          <img src={AboutSectionPreview1Img} alt={t('preview1')} />
        </div>
        <p>{t('paragraph1')}</p>
        <p>{t('paragraph2')}</p>
        <p>{t('paragraph3')}</p>
        <p>{t('paragraph4')}</p>
        <p>{t('paragraph5')}</p>
      </div>
      <div className={styles.aboutSection__previewContainer}>
        <img src={AboutSectionPreview1Img} alt={t('preview1')} />
        <div>
          <img src={AboutSectionPreview2Img} alt={t('preview2')} />
          <img src={AboutSectionPreview3Img} alt={t('preview3')} />
          <img src={AboutSectionPreview4Img} alt={t('preview4')} />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
