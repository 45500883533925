import React from 'react';
import {Switch, BrowserRouter} from 'react-router-dom';
import AppRoute from './AppRoute';
import Main from './main/Main';
import Config from 'app/Config';

const Routes: React.FC = () => {
  return (
    <BrowserRouter basename={Config.getPublicUrl()}>
      <Switch>
        <AppRoute exact path="/" component={Main} layout={Main} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
