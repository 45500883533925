import React from 'react';
import styles from './TechnologiesSection.module.scss';
import {
  SectionNumber,
  AdobeCreativeImg,
  AndroidImg,
  // ArrowRightImg,
  FigmaImg,
  HTML5Img,
  IOSImg,
  NestJSImg,
  NodeJSImg,
  ReactImg,
  SketchImg,
} from './assets';
import {useTranslation} from 'react-i18next';

const TechnologiesSection: React.FC = () => {
  const {t} = useTranslation('technologiesSection');
  return (
    <div className={styles.technologiesSection} id="technologiesSection">
      <p className={styles.technologiesSection__shortName}>
        <span>02&emsp;</span>
        {t('shortName')}
      </p>
      <img
        className={styles.technologiesSection__sectionNumber}
        src={SectionNumber}
        alt={t('sectionNumber')}
      />
      <div className={styles.technologiesSection__previewContainer}>
        <img src={IOSImg} alt="iOS" />
        <img src={AndroidImg} alt="Android" />
        <img className={styles.secondHide} src={ReactImg} alt="React" />
        <img className={styles.secondHide} src={NodeJSImg} alt="Node JS" />
        <img className={styles.firstHide} src={NestJSImg} alt="Nest JS" />
        <img className={styles.firstHide} src={HTML5Img} alt="HTML5" />
        <img
          className={styles.firstHide}
          src={AdobeCreativeImg}
          alt="Adobe Creative Cloud"
        />
        <img className={styles.firstHide} src={FigmaImg} alt="Figma" />
        <img className={styles.firstHide} src={SketchImg} alt="Sketch" />
      </div>
      <div className={styles.technologiesSection__descriptionContainer}>
        <h2>{t('title')}</h2>
        <p>{t('paragraph1')}</p>
        <p>{t('paragraph2')}</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {/* <a href="#"> */}
        {/*  {t('linkToTechnologies')} */}
        {/*  <img src={ArrowRightImg} alt={t('linkToTechnologiesImgAlt')} /> */}
        {/* </a> */}
      </div>
    </div>
  );
};

export default TechnologiesSection;
